import React, { Component } from 'react';
import './css/GetInfoFloat.css';
import Close from './../icons/close.svg';
import FileIcon from './../icons/file.png';
import FolderIcon from './../icons/folder.png';

export default class GetInfoFloat extends Component {
  render() {
    const {currentRoot, currentSelected, display, closeFloat} = this.props,
      entry = currentRoot.getChildren(currentSelected),
      isFile = display && entry.getType() === 'file';
    
    return display ? (
      <div className='container-get-info'>
        <div className='header'>
          <span className='title'>{`${isFile ? 'File' : 'Folder'} Info`}</span>
          <img src={Close} alt='close' className='close-btn' onClick={closeFloat}/>
        </div>
        <img className='icon-holder' src={isFile ? FileIcon : FolderIcon} alt='icon' />
        <div className='entry-property'>Name: {entry.props.name}</div>
        <div className='entry-property'>Size: {entry.props.size}</div>
        <div className='entry-property'>Creator Name: {entry.props.creatorName}</div>
        <div className='entry-property'>Created Date: {entry.props.createdDate}</div>
      </div>
    ) : null
  }
}
