let id = 0;
class Entry {
  constructor({name = null, size = null, creatorName = null, createdDate = null}) {
    this.props = {
      name: name,
      size: size,
      creatorName: creatorName,
      createdDate: createdDate
    };
    this.id = id++;
    this.parent = null;
    this.path = "/root/";
  }

  getParent() {
    return this.parent;
  }

  setParent(parent) {
    this.parent = parent;
    this._setPath();
  }

  getID() {
    return this.id;
  }

  _setPath() {
    const parent = this.getParent();
    if (parent === null) {
      this.path = '/root/';
    } else {
      this.path = this.getParent().getPath() + this.props.name + '/';
    }
  }

  getPath() {
    return this.path;
  }
}

export default Entry;
// module.exports= Entry;

