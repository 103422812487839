import FileEntry from './file-manager/File';
import FolderEntry from './file-manager/Folder';

// let FileEntry = require('./file-manager/File');
// let FolderEntry = require('./file-manager/Folder');

let rootFolder = new FolderEntry({
  name: 'root',
  size: 254,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
});


rootFolder.addChild(new FolderEntry({
  name: 'apps',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

rootFolder.addChild(new FolderEntry({
  name: 'pictures',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

rootFolder.addChild(new FolderEntry({
  name: 'videos',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

let docs = rootFolder.addChild(new FolderEntry({
  name: 'docs',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

rootFolder.addChild(new FileEntry({
  name: 'a.pdf',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

rootFolder.addChild(new FileEntry({
  name: 'b.jpg',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));


let work = docs.addChild(new FolderEntry({
  name: 'work',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

docs.addChild(new FileEntry({
  name: 'c.pdf',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));

docs.addChild(new FileEntry({
  name: 'd.docx',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));


work.addChild(new FileEntry({
  name: 'e.pdf',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));


work.addChild(new FileEntry({
  name: 'f.ts',
  size: 100,
  creatorName: 'Supratik',
  createdDate: new Date().toDateString()
}));


export default rootFolder;
