import React, { Component } from 'react';
import './css/CreateFloat.css';
import Close from './../icons/close.svg';

import File from './../utils/file-manager/File';
import Folder from './../utils/file-manager/Folder';

export default class CreateFloat extends Component {
  constructor() {
    super();
    this.state = {
      type: 'file'
    }

    this.name = React.createRef();
    this.creator = React.createRef();
    this.size = React.createRef();
    this.date = React.createRef();
  }

  createEntry = () => {
    let {closeFloat, currentRoot} = this.props,
      name = this.name.current.value || 'untitled',
      creatorName = this.creator.current.value || 'guest',
      size = this.size.current.value || '0',
      createdDate = this.date.current.value || new Date(),
      type = this.state.type,
      classInstance = type === 'file' ? File : Folder;

    let entry = new classInstance({name, creatorName, size, createdDate});
    currentRoot.addChild(entry);
    
    closeFloat();
  }

  render() {
    const {display, closeFloat} = this.props,
      type = this.state.type;
    return display ? (
      <div className='container-float'>
        <div className='header'>
          <span className='title'>Create New</span>
          <img src={Close} alt='close' className='close-btn' onClick={closeFloat}/>
        </div>
        <div className='button-container'>
          <div className={`file-btn ${type === 'file' ? 'active' : ''}`} onClick={() => this.setState({type: 'file'})}>File</div>
          <div className={`folder-btn  ${type === 'folder' ? 'active' : ''}`} onClick={() => this.setState({type: 'folder'})}>Folder</div>
        </div>
        <div className='input-container'>
          <input type='text' placeholder='Name' className='input' ref={this.name}/>
          <input type='text' placeholder='Creator' className='input' ref={this.creator}/>
          <input type='text' placeholder='Size' className='input' ref={this.size}/>
          <input type='text' placeholder='Date' className='input' ref={this.date}/>
        </div>
        <button className='create-btn' onClick={this.createEntry}>Create</button>
      </div>
    ) : null
  }
}
