import React, { Component } from 'react';
import './css/SearchBox.css';

import SearchResult from './SearchResult';

export default class SearchBox extends Component {
  constructor() {
    super();
    this.state = {
      searchResults: [],
      searchKey: ''
    }
  }

  changeHandler = (e) => {
    const rootFolder = this.props.rootFolder,
      searchKey = e.target.value.toLowerCase();
    let searchResults = [];
    if (searchKey !== '') {
      searchEntries(searchKey, rootFolder, searchResults);
    }
    this.setState({
      searchResults,
      searchKey
    })
  }

  render() {
    const {showContextMenu, changeCurrentRoot} = this.props;
    return (
      <div className='search-box'>
        <input type='text' className='input-box' placeholder='Search for anything' onChange={this.changeHandler}/>
        <SearchResult
          showContextMenu={showContextMenu}
          changeCurrentRoot={changeCurrentRoot}
          searchResults={this.state.searchResults}
          searchKey={this.state.searchKey}
        />
      </div>
    )
  }
}

function searchEntries(key, rootEntry, results) {
  if (rootEntry.props.name.toLowerCase().indexOf(key) > -1) {
    results.push(rootEntry);
  }

  if (rootEntry.children) {
    Object.keys(rootEntry.children).forEach(childKey => {
     searchEntries(key, rootEntry.getChildren(childKey), results)
    })
  }
}