import Entry from './Entry';
// let Entry = require('./Entry');
class File extends Entry {
  constructor(config) {
    super(config);
    this.type = 'file';
  }

  getType() {
    return this.type;
  }
}

export default File;

// module.exports = File;
