import React, { Component } from 'react';
import './css/ContextMenu.css';

export default class ContextMenu extends Component {

  handleOpen(currentRoot, currentSelected) {
    this.props.changeCurrentRoot(currentRoot.getChildren(currentSelected))
  }

  handleDelete(currentRoot, currentSelected) {
    currentRoot.removeChildren(currentSelected);
  }

  render() {
    const {displayContextMenu, clientX, clientY, currentSelected, currentRoot} = this.props;
    let selectedEntry = currentSelected && currentRoot.getChildren(currentSelected),
      isSelectedEntryFolder = selectedEntry && selectedEntry.getType() === 'folder';
    return displayContextMenu ? (
      <div className='context-menu' style={{left: clientX, top: clientY}}>
        { isSelectedEntryFolder && <div className='menu-item' onClick={() => this.handleOpen(currentRoot, currentSelected)}>Open</div>}
        <div className='menu-item' onClick={this.props.showGetInfoFloat}>Get Info</div>
        <div className='menu-item delete' onClick={() => this.handleDelete(currentRoot, currentSelected)}>Delete</div>
      </div>
    ) : null
  }
}
