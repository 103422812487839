import Entry from './Entry';

// let Entry = require('./Entry');

class Folder extends Entry {
  constructor(config) {
    super(config);
    this.type = 'folder';
    this.children = {};
  }

  getType() {
    return this.type;
  }

  addChild(child) {
    child.setParent(this);
    this.children[child.getID()] = child;
    return child;
  }

  getChildren(id) {
    return id ? this.children[id] : this.children;
  }

  removeChildren(id) {
    id ? (delete this.children[id]) : (delete this.children);
  }

}

export default Folder;

// module.exports = Folder;
