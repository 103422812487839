import React from 'react';
import Logo from './../icons/file.png';
import './css/File.css';

export default function File(props) {
  return (
    <div className='file' onContextMenu={(e) => popup(e, props)}>
      <img src={Logo} alt='file' height='60px'/>
      <br></br>
      <span>{props.name}</span>
    </div>
  )
}

function popup(e, props) {
  e.preventDefault();
  e.stopPropagation();
  props.showContextMenu(e.clientX, e.clientY, props.id);
}