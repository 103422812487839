import React from 'react';
import './css/Navbar.css';
import UpArrow from './../icons/up-arrow.png';

export default function Navbar(props) {
  return (
    <div className='path-container'>
      <img src={UpArrow} alt='up' className='up-arrow' onClick={() => handleClick(props)}></img>
      <div className='path'>{props.currentRoot.getPath()}</div>
    </div>
  )
}

function handleClick(props) {
  const {currentRoot, changeCurrentRoot} = props,
    parent = currentRoot.getParent();
  if (parent) {
    changeCurrentRoot(parent);
  } else {
    console.log('no parent')
  }
}