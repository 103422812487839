import React, { Component } from 'react';
import './css/App.css';

import File from './File';
import Folder from './Folder';
import Navbar from './Navbar';
import SearchBox from './SearchBox';
import CreateFloat from './CreateFloat';
import ContextMenu from './ContextMenu';
import GetInfoFloat from './GetInfoFloat';

import AddLogo from './../icons/add.png';
import ArrowLogo from './../icons/arrow.svg';
import FileLogo from './../icons/file.png';
import rootFolder from './../utils/init-filemanager';

class App extends Component {

  constructor() {
    super();
    this.state = {
      currentRoot: rootFolder,
      displayCreateFloat: false,
      displayContextMenu: false,
      displayGetInfoFloat: false,
      clientX: 0,
      clientY: 0,
      currentSelected: null
    }
  }

  changeCurrentRoot = currentRoot => {
    this.setState({
      currentRoot,
      displayCreateFloat: false,
      displayGetInfoFloat: false
    });
  }

  closeCreateFloat = () => {
    this.setState({
      displayCreateFloat: false
    })
  }

  showContextMenu = (clientX, clientY, currentSelected) => {
    this.setState({
      clientX,
      clientY,
      currentSelected,
      displayContextMenu: true
    });
    this.closeGetInfoFloat();
  }

  showGetInfoFloat = () => {
    this.setState({
      displayGetInfoFloat: true
    });
  }

  closeGetInfoFloat = () => {
    this.setState({
      displayGetInfoFloat: false
    });
  }

  hideContextMenu = () => {
    this.setState({
      displayContextMenu: false
    })
  }

  componentDidMount() {
    document.addEventListener('click', this.hideContextMenu);
  }

  render() {
    const {currentRoot, displayCreateFloat, displayContextMenu, clientX, clientY, currentSelected, displayGetInfoFloat} = this.state;

    let listGenerator = (folder) => {
      return Object.keys(folder.children).map(key => (
        <React.Fragment key={folder.children[key].id}>
          {folder.children[key].type === 'folder' ? (
            <li>
              <div className='item'>
                <input type="checkbox" id={folder.children[key].id}/>
                <img src={ArrowLogo} className='arrow' alt='arrow'/><label htmlFor={folder.children[key].id}>{folder.children[key].props.name}</label>
                <ul>
                  {listGenerator(folder.children[key])}
                </ul>
              </div>
            </li>
          ) : (
            <li>
              <img src={FileLogo} width='15px' alt='f' className='file-icon'/><label>{folder.children[key].props.name}</label>
            </li>
          )}
        </React.Fragment>
      ))
    }

    return (
      <div className='app-container'>
        <div className='left-section'>
          <div className='root'>root</div>
          <div className='multi-level'>
            <ul>
              {listGenerator(rootFolder)}
            </ul>
          </div>
        </div>

        <div className='right-section'>
          <Navbar 
            currentRoot={currentRoot}
            changeCurrentRoot={this.changeCurrentRoot}
          />
          <div className='file-container'>
            {
              Object.keys(currentRoot.children)
                .map(entryKey => {
                  const entry = currentRoot.children[entryKey];
                  if (entry.getType() === 'file') {
                    return <File 
                              showContextMenu={this.showContextMenu}
                              {...entry.props}
                              key={entry.id}
                              id={entry.id}
                            />
                  } else {
                    return <Folder 
                              showContextMenu={this.showContextMenu}
                              {...entry.props}
                              key={entry.id}
                              id={entry.id}
                              changeCurrentRoot={this.changeCurrentRoot}
                              entry={entry}
                            />
                  }
                })
            }
            <img 
              src={AddLogo}
              alt='add'
              className='add-logo'
              onClick={() => this.setState({displayCreateFloat: true})}
            />
          </div>
          <CreateFloat 
            display={displayCreateFloat}
            closeFloat={this.closeCreateFloat}
            currentRoot={currentRoot}
          />
          <ContextMenu
            displayContextMenu={displayContextMenu}
            clientX={clientX}
            clientY={clientY}
            currentRoot={currentRoot}
            currentSelected={currentSelected}
            changeCurrentRoot={this.changeCurrentRoot}
            showGetInfoFloat={this.showGetInfoFloat}
          />
          <GetInfoFloat
            currentRoot={currentRoot}
            currentSelected={currentSelected}
            display={displayGetInfoFloat}
            closeFloat={this.closeGetInfoFloat}
          />
        </div>
      
        <SearchBox 
          rootFolder={rootFolder}
          showContextMenu={this.showContextMenu}
          changeCurrentRoot={this.changeCurrentRoot}
        />
      </div>
    );
  }
}

export default App;
