import React from 'react';
import Logo from './../icons/folder.png';
import './css/Folder.css';

export default function Folder(props) {

  return (
    <div className='folder' 
      onDoubleClick={() => props.changeCurrentRoot(props.entry)}
      onContextMenu={(e) => popup(e, props)}
    >
      <img src={Logo} alt='folder'/>
      <br></br>
      <span>{props.name}</span>
    </div>
  )
}

function popup(e, props) {
  e.preventDefault();
  e.stopPropagation();
  props.showContextMenu(e.clientX, e.clientY, props.id);
}
