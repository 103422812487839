import React from 'react';
import './css/SearchResult.css';

import File from './File';
import Folder from './Folder';

export default function SearchResult(props) {
  const {searchResults, showContextMenu, changeCurrentRoot, searchKey} = props;
  return (
    <div className='search-result'>
      {searchKey !== '' ? searchResults.map(entry => (
        entry.getType() === 'file' ? <File id={entry.id} {...entry.props} key={entry.id} showContextMenu={showContextMenu} changeCurrentRoot={changeCurrentRoot}/>
          : <Folder id={entry.id} {...entry.props} key={entry.id} entry={entry} showContextMenu={showContextMenu} changeCurrentRoot={changeCurrentRoot}/>
      )): <div className='no-entries'>No Entries found</div>}
    </div>
  )
}
